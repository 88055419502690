<script>
    import Aws from '@/services/Aws';
    import { required, integer, minValue} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";


    export default {
        components:{
            flatPickr,
            validationMessages
        },
        props: ['applicationsData'],
        watch: {
            applicationsData: function(newVal) {
                if(newVal){
                    this.applications = newVal;
                }
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader:false,
                showLoader2:false,
                modalTitle:'',
                applications:[],
                application_id:'',
                rdsCluster:'',
                targetgroup:'',
                autoScalingGroups:[],
                autoScalingGroup:[],
                action:'',
                MinSize: 0,
                MaxSize: 0,
                start_at: '',
                DBInstances: 1,
                readerCount:[],
                classTypes:[],
                classType:'',
                datetimeconfig: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: new Date(),
                    minuteIncrement: 10
                },
            };
        },
        validations() {
            // Check if the action is 'update_auto_scaling_group'
            if (this.action === 'update_auto_scaling_group') {
                return {
                    application_id:{
                      required
                    },
                    action:{
                        required
                    },
                    start_at:{
                        required
                    },
                    MinSize: {
                        required,
                        integer,
                        minValue: minValue(1),
                        valid: function() {
                            return this.minSizeMaxCheck()
                        }
                    },
                    MaxSize: {
                        required,
                        integer,
                        minValue: minValue(1),
                        valid: function() {
                            return this.minSizeMaxCheck()
                        }
                    }
                };
            } if(this.action === 'read_db_instance') {
                return {
                    application_id:{
                        required
                    },
                    action:{
                        required
                    },
                    start_at:{
                        required
                    },
                    DBInstances:{
                        required,
                        minValue: minValue(1),
                    },
                    classType:{
                        required
                    }
                };
            } else {
                return {
                    application_id:{
                        required
                    },
                    action:{
                        required
                    },
                    start_at:{
                        required
                    }
                };
            }
        },
        methods: {

            minSizeMaxCheck () {
                return this.MinSize <= this.MaxSize || !this.MinSize || !this.MaxSize;
            },

            async getAutoScalingGroups() {
                try {
                    const response = await Aws.getAutoScalingGroups();
                    this.autoScalingGroups = response.data.data;
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async addScheduledAction(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        let params = {
                            start_at: this.start_at,
                            application_id: this.application_id,
                            action_name: this.action,
                            csrf_token: this.csrf_token
                        }

                        if(this.action == 'update_auto_scaling_group'){
                            params.input = {
                                AutoScalingGroupName: this.autoScalingGroup.AutoScalingGroupName,
                                MinSize: this.MinSize,
                                MaxSize: this.MaxSize,
                            }
                        }
                        if(this.action == 'read_db_instance'){
                            params.input = {
                                DBInstances: this.DBInstances,
                                DBClusterIdentifier: this.rdsCluster,
                                classType: this.classType
                            }
                        }

                        await Aws.addScheduledAction(params).then((response) => {
                            this.successmsg("Auto Scaling Group successfully edited");
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error);
                        }).finally(() => {
                            this.refreshData();
                            this.tryingToEdit = false;
                        });
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    }
                }

                this.tryingToEdit = false;
            },

            onChangeApplication(){
                if(this.application_id){
                    this.rdsCluster = this.getRdsCluster(this.application_id)
                    this.targetgroup = this.getTargetGroup(this.application_id)
                    this.getRDSInstances()
                }
                this.action = ''
            },

            onChangeAction(){
                if(this.action == 'update_auto_scaling_group'){
                    this.autoScalingGroup = this.getAutoScalingGroupByTargetGroup(this.targetgroup)
                    this.MinSize = this.autoScalingGroup.MinSize
                    this.MaxSize = this.autoScalingGroup.MaxSize
                }
            },

            getRdsCluster(applicationId) {
                const app = this.applications.find(app => app.id === applicationId);
                if (app) {
                    const rdsResource = app.resources.find(resource => resource.resource_type === "rds_cluster");
                    this.selectedRdsCluster = rdsResource ? rdsResource.resource_id : null;
                } else {
                    this.selectedRdsCluster = null;
                }
                return this.selectedRdsCluster;
            },

            getTargetGroup(applicationId) {
                const app = this.applications.find(app => app.id === applicationId);
                if (app) {
                    const targetGroupResource = app.resources.find(resource => resource.resource_type === "targetgroups");
                    this.selectedTargetGroup = targetGroupResource ? targetGroupResource.resource_id : null;
                } else {
                    this.selectedTargetGroup = null;
                }
                return this.selectedTargetGroup;
            },

            getAutoScalingGroupByTargetGroup(targetGroupArn) {
                const autoScalingGroup = this.autoScalingGroups.find(group => group.TargetGroupARNs.includes(targetGroupArn));
                this.selectedAutoScalingGroup = autoScalingGroup || null;
                return this.selectedAutoScalingGroup;
            },

            getRDSInstances() {
                this.showLoader2 = true
                Aws.getRDSInstances({
                    DBClusterIdentifier: this.rdsCluster
                })
                    .then((response) => {
                        if (response.data) {
                            let DBinstances = response.data.data;
                            // Organize instances by DBClusterIdentifier
                            const groupedInstances = DBinstances.reduce((acc, instance) => {
                                const clusterId = instance.DBClusterIdentifier || 'No Cluster'; // Default to 'No Cluster' if not present
                                if (!acc[clusterId]) {
                                    acc[clusterId] = [];
                                }

                                acc[clusterId].push(instance);

                                return acc;
                            }, {});

                            this.readerCount = Object.keys(groupedInstances).reduce((countAcc, clusterId) => {
                                countAcc[clusterId] = groupedInstances[clusterId].filter(
                                    instance => instance.InstanceRole === 'reader' && instance.DBInstanceStatus != 'deleting'
                                ).length;
                                return countAcc;
                            }, {});

                            this.DBInstances = this.readerCount[this.rdsCluster] !== undefined ? this.readerCount[this.rdsCluster] : 1
                        }
                    })
                    .catch((error) => {
                        this.error = error.response && error.response.data && error.response.data.error
                            ? error.response.data.error
                            : "An error occurred";
                        this.tableData = [];

                    }).finally(() => {
                        this.showLoader2 = false
                    });
            },

            getRDSClassTypes(){
                Aws.getRDSClassTypes().then((response) => {
                    if(response.data.data){
                        let classes = response.data.data;
                        this.classTypes = classes.map(c => c.class_name)

                        this.classType = this.DBInstanceClass
                    }
                }).catch((error) => {
                    this.error = error.response.data.error
                        ? error.response.data.error
                        : "";
                }).finally(() => {

                });
            },

            refreshData() {
                this.$emit('onRefresh')
            },

            closeModal() {
                this.showModal = false;
            },

            initialData(){
                this.getAutoScalingGroups()
                this.getRDSClassTypes()
            },

            resetForm(){
                this.start_at = ''
                this.application_id = ''
                this.action = ''
                this.MinSize = 0
                this.MaxSize = 0
                this.DBInstances = 1
                this.submitted = false
            }
        }
    };
</script>

<template>
    <b-modal @shown="initialData" v-model="showModal" id="add_ScheduledAction" title="Add Scheduled Action" title-class="font-18" @hidden="resetForm" centered>
        <form @submit.prevent="addScheduledAction" v-if="!showLoader">

            <div class="row">

                <div class="col-sm-12">
                    <label class="form-label mb-2">Start At</label>
                    <flat-pickr
                            v-model="start_at"
                            :config="datetimeconfig"
                            class="form-control mb-2"
                            :class="{ 'is-invalid': submitted && $v.start_at.$error }"
                            name="published"
                    />
                    <validationMessages v-if="submitted" :fieldName="'Start At'" :validationName="$v.start_at"></validationMessages>
                </div>
                <div class="col-sm-12">
                    <b-form-group label="Select Application" label-for="formrow-application-input">
                        <select
                                v-model="application_id"
                                :class="{ 'is-invalid': submitted && $v.application_id.$error }"
                                class="form-select"
                                @change="onChangeApplication"
                        >
                            <b-form-select-option :value="null">Please select application</b-form-select-option>
                            <option
                                    v-for="application in applications"
                                    :key="application.id"
                                    :value="application.id"
                            >
                                {{ application.name }}
                            </option>

                        </select>
                        <validationMessages v-if="submitted" :fieldName="'Application'" :validationName="$v.application_id"></validationMessages>
                    </b-form-group>
                </div>

                <div class="col-sm-12">
                    <b-form-group label="Select Action" label-for="formrow-action-input" class="mb-3">
                        <select
                                v-model="action"
                                :class="{ 'is-invalid': submitted && $v.action.$error }"
                                class="form-select"
                                @change="onChangeAction"
                        >
                            <b-form-select-option value="null">Please select action</b-form-select-option>
                            <b-form-select-option value="update_auto_scaling_group">Application Servers</b-form-select-option>
                            <b-form-select-option value="read_db_instance">Database Reader Instances</b-form-select-option>

                        </select>
                        <validationMessages v-if="submitted" :fieldName="'Action'" :validationName="$v.action"></validationMessages>
                    </b-form-group>
                </div>

            </div>

            <div class="row" v-if="action == 'update_auto_scaling_group'">

                <div class="col-sm-12">
                    <h5 class="text-center">{{autoScalingGroup.AutoScalingGroupName}}</h5>
                </div>
                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="minSize">Min Size:</label>
                        <input type="number" id="minSize"
                               class="form-control"
                               v-model.number="MinSize"
                               min="1" max="10"
                               :class="{
                                  'is-invalid': submitted && $v.MinSize.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'Min Size'" :validationName="$v.MinSize"></validationMessages>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="maxSize">Max Size:</label>
                        <input type="number" id="maxSize"
                               class="form-control"
                               v-model.number="MaxSize"
                               min="1" max="100"
                               :class="{
                                  'is-invalid': submitted && $v.MaxSize.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'Max Size'" :validationName="$v.MaxSize"></validationMessages>
                    </div>
                </div>

            </div>

            <div class="row" v-if="action == 'read_db_instance'">
                <div class="col-sm-12">
                    <h5 class="text-center">Cluster Name: {{rdsCluster}} </h5>
                </div>

                <div class="col-sm-12 col-md-12" v-if="!showLoader2">
                    <label class="control-label form-label">Choose Instance Class Type</label>

                    <b-form-select
                            class="form-control"
                            aria-describedby="instanceClassType"
                            v-model="classType"
                            :class="{
                      'is-invalid': submitted && $v.classType.$error,
                    }"
                    >
                        <option v-for="type in classTypes" :key="type" :value="type ">
                            {{ type }}
                        </option>
                    </b-form-select>
                    <validationMessages v-if="submitted" :fieldName="'Class Type'" :validationName="$v.classType"></validationMessages>
                </div>

                <div class="col-sm-12 col-md-12" v-if="!showLoader2">
                    <div class="form-group">
                        <label for="dbInstances">Total Number of Read DB Instances :</label>
                        <input type="number" id="dbInstances"
                               class="form-control"
                               v-model.number="DBInstances"
                               min="1" max="10"
                               :class="{
                                  'is-invalid': submitted && $v.DBInstances.$error,
                                }"
                        />
                        <validationMessages v-if="submitted" :fieldName="'DB Instances'" :validationName="$v.DBInstances"></validationMessages>
                    </div>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center" v-else>
                    <b-spinner large></b-spinner>
                </div>
            </div>

        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addScheduledAction" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Add</b-button>
        </template>
    </b-modal>
</template>